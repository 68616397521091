import repository from '@/api/raffle/index'

export default {
    state: {
        user: null,
        promoList: [],
        dropdowns:{
            promo_list: [],
            area_list: [],
            store_branch_list: [],
            provinces_list: [],
            side_bar: []
        },
        side_menu_bars: [],
        active_promo: 0,
    },
    getters: {
        USERACCOUNT_LOAD: (state) => state.user,
        GET_ALL_DROPDOWN: (state) => state.dropdowns,
        GET_ACTIVE_PROMO: (state) => state.active_promo,
        GET_SIDE_MENU_BARS: (state) => state.side_menu_bars
        // GET_OTP_CODE: (state) => state.otpCode,
    },
    actions: {
        urlGet({commit}, payload){
            return new Promise((resolve, reject)=>{
                payload.event_id = this.getters.GET_EVENT_ID
                repository.urlGet(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        urlPost({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlPost(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlPut({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlPut(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlDelete({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlDelete(payload).then((response)=>{
                    commit('NEW_MASTER_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        urlShow({commit}, payload){
            payload.event_id = this.getters.GET_EVENT_ID
            return new Promise((resolve, reject)=>{
                repository.urlShow(payload).then((response)=>{
                    // commit('NEW_MASTER_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    if(error.response.status == 401){
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result)=>{
                            if(result.isConfirmed){
                                localStorage.removeItem("token");
                                router.push('/dashboard');
                            }
                        })
                    }
                    reject(error)
                })
            })
        },
        credentials({ commit }) {
            return new Promise((resolve, reject) => {
              repository.useraccount().then(({ data, status }) => {
                    if (status == 200) {
                        commit('ACCOUNT_LOAD', data)
                        resolve(data)
                    }
                })
                .catch((error) => {
                  reject(error)
                });
            });
        },
        renderSideNav({ commit }) {
            return new Promise((resolve, reject) => {
              repository.renderSideNav().then(({ data, status }) => {
                    if (status == 200) {
                        commit('SIDE_MENU_BARS', data)
                        resolve(data)
                    }
                })
                .catch((error) => {
                  reject(error)
                });
            });
        },
    },
    mutations: {
        ACCOUNT_LOAD: (state, data) => {
            state.user = data;
        },
        MUTATE_DROPDOWNS: (state, data) =>{
            state.dropdowns[data.state_name] = data.state_data
        },
        ACTIVE_PROMO: (state, data) =>{
            state.active_promo = data
        },
        SIDE_MENU_BARS: (state, data) =>{
            state.side_menu_bars = data
        },
    }
}
